:root {
   --primary-color: #eee3d1;
   --text-color: black;
   --background-color: #f9f9f9;
   --hover-color: #d6ccb9;
   --inactive-color: rgba(0, 0, 0, 0.5);
   --gold: #c8a97e;
   --gold-light: #e0c9a6;
}

/* Temel Animasyonlar */
@keyframes elegantFadeIn {
   0% {
      opacity: 0;
      transform: translateY(20px) scale(0.98);
   }
   100% {
      opacity: 1;
      transform: translateY(0) scale(1);
   }
}

@keyframes borderShimmer {
   0% {
      background-position: 0% center;
   }
   100% {
      background-position: -200% center;
   }
}

@keyframes smoothReveal {
   0% {
      clip-path: inset(0 100% 0 0);
   }
   100% {
      clip-path: inset(0 0 0 0);
   }
}

.menu-container {
   padding: 0px;
   margin: 0 auto;
   animation: elegantFadeIn 1s cubic-bezier(0.4, 0, 0.2, 1);
}

.sticky-category-bar-wrapper {
   position: sticky;
   top: 0;
   width: 100%;
   background: rgba(249, 249, 249, 0.85);
   backdrop-filter: blur(8px);
   z-index: 100;
   padding: 15px 0;
   border-bottom: 1px solid rgba(200, 169, 126, 0.2);
   transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.category-scroll-container {
   display: flex;
   gap: 10px;
   padding: 0 20px;
   margin: 0 auto;
   white-space: nowrap;
   overflow-x: auto;
   scroll-behavior: smooth;
   -webkit-overflow-scrolling: touch;
   scrollbar-width: none;
   -ms-overflow-style: none;
   scroll-snap-type: x mandatory;
}

.category-scroll-container::-webkit-scrollbar {
   display: none;
}

.category-button {
   font-size: 16px;
   color: var(--inactive-color);
   cursor: pointer;
   padding: 12px 24px;
   position: relative;
   transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
   background: none;
   border: none;
   letter-spacing: 1px;
   text-transform: uppercase;
   white-space: nowrap;
   flex-shrink: 0;
   scroll-snap-align: center;
}

.category-button::after {
   content: '';
   position: absolute;
   bottom: 0;
   left: 50%;
   width: 0;
   height: 2px;
   background: var(--gold);
   transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
   transform: translateX(-50%);
   opacity: 0;
}

.category-button.active {
   color: var(--text-color);
   font-weight: 500;
}

.category-button.active::after {
   width: 90%;
   opacity: 1;
}

.menu-section-popup {
   opacity: 0;
   transform: translateY(30px);
   animation: elegantFadeIn 0.8s cubic-bezier(0.4, 0, 0.2, 1) forwards;
   will-change: transform, opacity;
}

.menu-section {
   margin-bottom: 120px;
   opacity: 0;
   transform: translateY(30px);
   animation: elegantFadeIn 0.8s cubic-bezier(0.4, 0, 0.2, 1) forwards;
   will-change: transform, opacity;
}

.menu-category-title {
   font-size: 32px;
   color: var(--text-color);
   text-align: center;
   margin-bottom: 40px;
   position: relative;
   padding-bottom: 15px;
}

.menu-category-title::after {
   content: '';
   position: absolute;
   bottom: 0;
   left: 50%;
   transform: translateX(-50%);
   width: 120px;
   height: 2px;
   background: linear-gradient(
      90deg,
      transparent 0%,
      var(--gold) 20%,
      var(--gold-light) 50%,
      var(--gold) 80%,
      transparent 100%
   );
   background-size: 200% 100%;
   animation: borderShimmer 3s linear infinite;
}

.menu-subcategory-section {
   background: rgba(255, 255, 255, 0.7);
   border-radius: 12px;
   margin: 0 auto 20px;
   box-shadow: 0 8px 30px rgba(0, 0, 0, 0.08),
      0 4px 12px rgba(200, 169, 126, 0.08);
   backdrop-filter: blur(10px);
   overflow: hidden;
   width: 95%;
   max-width: 1000px;
   transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
   position: relative;
   isolation: isolate;
}

.menu-subcategory-section.expanded {
   background: rgba(255, 255, 255, 1);
   box-shadow: 0 12px 40px rgba(0, 0, 0, 0.12),
      0 6px 16px rgba(200, 169, 126, 0.15);
   transform: translateY(-2px);
}

.menu-subcategory-section::before {
   content: '';
   position: absolute;
   inset: 0;
   background: rgba(255, 255, 255, 0.9);
   z-index: -1;
}

.menu-subcategory-title {
   font-size: 24px;
   color: var(--text-color);
   text-align: center;
   margin-bottom: 0;
   position: relative;
   padding: 15px 60px;
   font-weight: 500;
   background: transparent;
   border: none;
   width: 100%;
   min-height: 64px;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
   transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
   border-radius: 8px;
   text-transform: none;
   z-index: 1;
}

.menu-subcategory-title:hover {
   background: rgba(200, 169, 126, 0.08);
}

.menu-subcategory-title.expanded {
   background: rgba(200, 169, 126, 0.25);
   font-weight: 600;
   color: var(--gold);
}

.menu-subcategory-section.expanded .menu-item {
   background: rgba(255, 255, 255, 0.7);
}

.dropdown-arrow {
   font-size: 14px;
   color: var(--gold);
   position: absolute;
   right: 20px;
   margin-left: 0;
   opacity: 0.7;
   transition: all 0.3s ease;
}

.menu-subcategory-title.expanded .dropdown-arrow {
   opacity: 1;
   color: var(--gold);
}

.subcategory-items {
   overflow: hidden;
   will-change: opacity;
   margin: 0;
   padding: 0;
}

.menu-item-container {
   width: 95%;
   max-width: 1000px;
   margin: 0 auto;
}

.menu-item {
   display: flex;
   flex-direction: column;
   padding: 20px;
   margin: 20px auto;
   border-radius: 8px;
   background: rgba(255, 255, 255, 0.4);
   transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
   position: relative;
   overflow: hidden;
   box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08),
      0 2px 8px rgba(200, 169, 126, 0.05);
   width: 92%;
}

.menu-item:first-child {
   margin-top: 50px;
}

.menu-item:last-child {
   margin-bottom: 50px;
}

.menu-item-details {
   flex: 1;
   width: 100%;
}

.menu-item-name {
   font-size: 20px;
   font-weight: 500;
   color: var(--text-color);
   margin: 0;
   transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
   position: relative;
   display: inline-block;
   white-space: pre-wrap;
}

.menu-item-description {
   font-size: 15px;
   color: var(--inactive-color);
   margin: 8px 0;
   line-height: 1.6;
   transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
   max-width: 80%;
}

.menu-item-flavor {
   font-size: 13px;
   color: rgba(0, 0, 0, 0.5);
   margin: 8px 0;
   line-height: 1.4;
   transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
   max-width: 80%;
}

.menu-item-price {
   font-size: 18px;
   font-weight: 600;
   color: var(--gold);
   padding: 6px 12px;
   border-radius: 4px;
   background: rgba(200, 169, 126, 0.1);
   transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
   align-self: flex-end;
   margin-top: 10px;
}

.menu-item::before {
   content: '';
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: linear-gradient(
      45deg,
      transparent,
      rgba(200, 169, 126, 0.1),
      transparent
   );
   transform: translateX(-100%);
   transition: transform 0.6s ease;
}

.menu-item:hover {
   transform: translateY(-4px);
   box-shadow: 0 8px 20px rgba(0, 0, 0, 0.12),
      0 4px 12px rgba(200, 169, 126, 0.08);
   background: rgba(255, 255, 255, 0.6);
}

.menu-item:hover::before {
   transform: translateX(100%);
}

.menu-item:hover .menu-item-name {
   color: var(--gold);
   transform: translateX(4px);
}

.menu-item:hover .menu-item-description {
   color: var(--text-color);
}

.menu-item:hover .menu-item-price {
   background: rgba(200, 169, 126, 0.2);
   transform: scale(1.05);
}

/* Staggered entrance for menu items */
.menu-item {
   opacity: 0;
   animation: elegantFadeIn 0.8s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.menu-item:nth-child(1) {
   animation-delay: 0.1s;
}
.menu-item:nth-child(2) {
   animation-delay: 0.2s;
}
.menu-item:nth-child(3) {
   animation-delay: 0.3s;
}
.menu-item:nth-child(4) {
   animation-delay: 0.4s;
}
.menu-item:nth-child(5) {
   animation-delay: 0.5s;
}

.menu-content {
   margin-top: 80px;
}

@media (max-width: 768px) {
   .sticky-category-bar-wrapper {
      padding: 10px 0;
   }

   .category-scroll-container {
      padding: 0 15px;
      gap: 5px;
      justify-content: flex-start;
      width: 100%;
   }

   .category-button {
      padding: 8px 16px;
      font-size: 14px;
      scroll-snap-align: center;
   }

   .menu-content {
      margin-top: 60px;
   }

   .menu-container {
      padding-top: 0;
   }

   .menu-section-popup {
      scroll-margin-top: 70px;
   }

   .menu-section {
      scroll-margin-top: 70px;
   }

   .menu-category-title {
      font-size: 28px;
      margin-bottom: 30px;
   }

   .menu-subcategory-title {
      font-size: 20px;
   }

   .menu-item-name {
      font-size: 18px;
   }

   .menu-item-description {
      font-size: 14px;
      max-width: 100%;
   }

   .menu-item-price {
      font-size: 16px;
   }

   .menu-item {
      width: 92%;
   }

   .menu-item:first-child {
      margin-top: 30px;
   }

   .menu-item:last-child {
      margin-bottom: 30px;
   }

   .menu-subcategory-section {
      width: 92%;
      max-width: none;
   }

   .menu-item-container {
      width: 92%;
      max-width: none;
   }
}

/* Smooth scroll behavior */
html {
   scroll-behavior: smooth;
}

.menu-item-popup-name {
   color: #001f3f;
   font-size: 16px;
   text-align: center;
}

/* Popup Styles */
.popup {
   position: relative;
   background-color: rgba(255, 255, 255, 0.95);
   box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
   padding: 20px;
   z-index: 1000;
   border-radius: 8px;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-bottom: 10px;
   width: 90%;
   max-width: 400px;
   margin-left: auto;
   margin-right: auto;
}

.popup-content {
   flex-grow: 1;
   text-align: center;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

.close {
   cursor: pointer;
   font-size: 20px;
   color: #333;
   margin-left: 10px;
}
