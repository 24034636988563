/* Navbar temel stil */
.navbar {
   display: flex;
   position: absolute;
   top: 0;
   z-index: 1000;
   width: 100%;
   height: 6rem;
   justify-content: flex-end;
   align-items: center;
   transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Sticky navbar durumunda yeşil arka plan */
.navbar.sticky {
   position: sticky;
   top: 0;
}

/* Navbar içindeki konteyner */
.navbar-container {
   position: relative;
   width: 100%;
   display: flex;
   justify-content: flex-end;
   align-items: center;
   padding: 1rem 2.5rem;
}

.navbar-logo {
   position: absolute;
   left: 50%;
   transform: translateX(-50%);
   width: 140px;
   height: auto;
   display: flex;
   align-items: center;
   justify-content: center;
   z-index: 999;
}

.navbar-logo img {
   width: 100%;
   height: auto;
   object-fit: contain;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
   .navbar-logo {
      transform: translateX(-50%) translateZ(0);
   }
}

/* Menü ikonunun stili */
.menu-icon {
   cursor: pointer;
   display: flex;
   flex-direction: column;
   margin-right: 0;
   gap: 6px;
   z-index: 1000;
   text-align: center;
   position: relative;
   right: 0;
   width: 30px;
   height: 20px;
}

.line {
   width: 100%;
   height: 2px;
   background-color: currentColor;
   transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
   position: absolute;
}

.line:nth-child(1) {
   top: 0;
}

.line:nth-child(2) {
   top: 50%;
   transform: translateY(-50%);
}

.line:nth-child(3) {
   bottom: 0;
}

/* Menü ve geçiş animasyonları */
.menu {
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background: rgba(0, 0, 0, 0.65);
   backdrop-filter: blur(15px);
   -webkit-backdrop-filter: blur(15px);
   color: white;
   display: flex;
   justify-content: center;
   align-items: center;
   opacity: 0;
   visibility: hidden;
   transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.menu.active {
   opacity: 1;
   visibility: visible;
}

.menu ul {
   list-style: none;
   display: flex;
   flex-direction: column;
   gap: 40px;
   text-align: center;
   padding: 0;
}

.menu ul li {
   text-transform: uppercase;
   font-size: 24px;
   opacity: 0;
   transform: translateY(20px);
   transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.menu.active ul li {
   opacity: 1;
   transform: translateY(0);
}

.menu.active ul li:nth-child(1) {
   transition-delay: 0.1s;
}
.menu.active ul li:nth-child(2) {
   transition-delay: 0.2s;
}
.menu.active ul li:nth-child(3) {
   transition-delay: 0.3s;
}
.menu.active ul li:nth-child(4) {
   transition-delay: 0.4s;
}

.menu ul li a {
   color: white;
   text-decoration: none;
   font-weight: normal;
   position: relative;
   display: inline-block;
   padding: 5px 0;
}

.menu ul li a.active {
   font-weight: 700;
}

.menu ul li a::after {
   content: '';
   position: absolute;
   bottom: 0;
   left: 0;
   width: 0;
   height: 2px;
   background-color: white;
   transition: width 0.3s ease;
}

.menu ul li a:hover::after {
   width: 100%;
}

/* Menü ikonunun dönüşümü */
.navbar.open .line:nth-child(1) {
   top: 50%;
   transform: translateY(-50%) rotate(45deg);
}

.navbar.open .line:nth-child(2) {
   opacity: 0;
   transform: translateX(20px);
}

.navbar.open .line:nth-child(3) {
   bottom: 50%;
   transform: translateY(50%) rotate(-45deg);
}

/* Mobil ve masaüstü uyumlu */
@media (max-width: 768px) {
   .navbar {
      height: 4rem;
   }

   .navbar-container {
      padding: 0.5rem 1.5rem;
   }

   .navbar-logo {
      width: 90px;
   }

   .menu-icon {
      margin-right: 0;
      position: absolute;
      right: 1.5rem;
   }

   .line {
      width: 22px;
   }
}

.language-menu-item {
   margin-top: 20px !important;
   font-size: 18px !important;
   opacity: 0.8;
   cursor: pointer;
   transition: all 0.3s ease;
   display: flex;
   justify-content: center;
   gap: 8px;
}

.language-menu-item:hover {
   opacity: 1;
}

.language-option {
   padding: 0 5px;
   transition: all 0.3s ease;
   position: relative;
   cursor: pointer;
}

.language-option::after {
   content: '';
   position: absolute;
   bottom: -2px;
   left: 0;
   width: 0;
   height: 2px;
   background-color: white;
   transition: width 0.3s ease;
}

.language-option:hover::after {
   width: 100%;
}

.language-option.active {
   font-weight: 700;
   opacity: 1;
}

.language-option.active::after {
   width: 100%;
}

.language-option:not(.active) {
   opacity: 0.6;
}

.language-option:not(.active):hover {
   opacity: 0.9;
}

.language-separator {
   opacity: 0.4;
}

/* Navbar logo animation */
.navbar-logo {
   transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.navbar-logo:hover {
   transform: translateX(-50%) scale(1.05);
}
